import { IMxTransaction } from "../store/modules/MXAPI/models";
import { ITransaction } from "../store/modules/NarmiAPI/models";

export function getMXTransactionsByType (transactions: IMxTransaction[], type: number) {
    return transactions.filter((transaction) => transaction.transaction_type === type).map((transaction) => transaction.amount);
}

export function getNarmiTransactionsByType (transactions: ITransaction[], type: number) {
    switch (type) {
        case 2:
            return transactions.filter((transaction) => transaction.amount > 0).map((transaction) => parseFloat((transaction.amount / 100).toFixed(2)));
        case 1:
            return transactions.filter((transaction) => transaction.amount < 0).map((transaction) => parseFloat((transaction.amount / 100).toFixed(2)));
        default:
            return [];
        }
}

export function isNarmiTransaction(transaction: any): transaction is ITransaction {
    return 'settled_at' in transaction;
}
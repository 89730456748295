export const sumBalances = (numbers: (number | null | undefined)[]): number => {
    const filteredNumbers = numbers.filter((num): num is number => num !== null && num !== undefined);
      return filteredNumbers.reduce((total, num) => total + num, 0);
};

export const calculatePercentageChange = (current: number, previous: number): number => {
    if (previous === 0) return current > 0 ? 100 : current < 0 ? -100 : 0;
    return ((current - previous) / previous) * 100;
  };
  
  export const formatMoney = (amount: number): string => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  
    const formattedAmount = formatter.format(amount);
    return formattedAmount.replace('$', '');

};

export function formatUserName(name: string): string {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}
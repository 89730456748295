export const conversionTable = [
    { dpmo: 934000, score: 0 },
    { dpmo: 880000, score: 5 },
    { dpmo: 820000, score: 10 },
    { dpmo: 760000, score: 15 },
    { dpmo: 700000, score: 20 },
    { dpmo: 640000, score: 25 },
    { dpmo: 580000, score: 30 },
    { dpmo: 520000, score: 35 },
    { dpmo: 460000, score: 40 },
    { dpmo: 400000, score: 45 },
    { dpmo: 340000, score: 50 },
    { dpmo: 280000, score: 55 },
    { dpmo: 220000, score: 60 },
    { dpmo: 160000, score: 65 },
    { dpmo: 100000, score: 70 },
    { dpmo: 50000, score: 75 },
    { dpmo: 25000, score: 80 },
    { dpmo: 10000, score: 85 },
    { dpmo: 5000, score: 90 },
    { dpmo: 2000, score: 95 },
    { dpmo: 1000, score: 98 },
    { dpmo: 500, score: 100 },
];
  
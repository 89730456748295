import { useSelector } from 'react-redux';
import { TRootState } from '../..';
import api from '../../api';
import { InitialState } from '../../slices/authSlice';
import { IUserAccountsResponse, IUserTransactionsResponse } from './models';


const useNarmiAPI = () => {

 const apiURL = process.env.REACT_APP_SECRETS_API_URL;
 const token = useSelector(
  (state: TRootState) => (state.auth as InitialState).token
);

  return api.injectEndpoints({
    endpoints: (build) => ({
      getUserAccounts: build.query<IUserAccountsResponse, void>({
        query: () => {
          return {
            url: `${apiURL}/getNarmiAccounts`, 
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            }
          };
        },
      }),
      getUserTransactions: build.query<IUserTransactionsResponse, {search?: string, records_per_page?: number;}>({
        query: ({ search, records_per_page }) => {
          const params = new URLSearchParams();
          if (search) params.append('q', search);
          if (records_per_page) params.append('per-page', records_per_page.toString());
      
          return {
            url: `${apiURL}/getNarmiTransactions${params.toString() ? `?${params.toString()}` : ''}`,
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            }
          };
        },
      }),
    }),
  });
}

export default useNarmiAPI;


import { useDispatch } from "react-redux";
import { setIsAuthorized, setIsLoadingYTD } from "../store/slices/authSlice";
import NarmiAPI from "../store/modules/NarmiAPI";
import { useEffect, useState } from "react";
import { calculateYTD } from "../utils/calculations";


const useYTD = () => {
  const dispatch = useDispatch();
  const [getNarmiUserTransactions] = NarmiAPI().useLazyGetUserTransactionsQuery();
  const [YTD, setYTD] = useState('0.00');


  useEffect(() => {
    const getUserDetails = async () => {
      try {
        dispatch(setIsLoadingYTD(true));

        const startOfYear = new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0];

        const narmiTransactionsYTDData =  await getNarmiUserTransactions({
          search: `settled>${startOfYear}`,
          records_per_page: 1000
        }).unwrap();
        
        //YTD Interest Earned for LiaFi Account calculation
        const YTD = calculateYTD(narmiTransactionsYTDData.transactions);
        setYTD(YTD);

      
      } catch (error) {
        console.error("Something went wrong");
        dispatch(setIsAuthorized(false));
      } finally {
        dispatch(setIsLoadingYTD(false));
      }
    };
    getUserDetails();
  }, [dispatch, getNarmiUserTransactions]);

  return {
    YTD
  };
};

export default useYTD;

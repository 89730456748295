import api from "../../api";
import { IAuthResponse } from "./models";

const apiURL = process.env.REACT_APP_SECRETS_API_URL;

const authAPI = api.injectEndpoints({
    endpoints: (build) => ({
      getAuth: build.mutation<IAuthResponse, {id: string}>({
        query: ({id}) => ({
          url: `${apiURL}/auth/${id}`,
          method: 'GET'
        }),
      }),
    }),
});

export default authAPI;

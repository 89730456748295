import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import persistStore from 'redux-persist/es/persistStore';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';

import api from './api';
import authSlice from './slices/authSlice';

const persistConfig: PersistConfig<TRootState> = {
  key: 'root',
  storage, 
  whitelist: [ 'auth' ],
  stateReconciler: autoMergeLevel2
}

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  [api.reducerPath]: api.reducer,
})

const persistedReducers = persistReducer<ReturnType<typeof rootReducer>>(
  persistConfig,
  rootReducer
)

export const store = configureStore({
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(api.middleware),
});
export const persistor = persistStore(store)
export const useAppDispatch: () => TAppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector;

export type TRootState = ReturnType<typeof rootReducer>;
export type TAppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type InitialState = {
  isAuthorized: boolean;
  interestRate: number;
  user: string;
  token: string;
  isLoadingAccountDetails: boolean;
  isLoadingYTD: boolean;
  noExternalAccounts: boolean;
};

const initialState: InitialState = {
  isAuthorized: false,
  interestRate: 2.5,
  user: '',
  token: '',
  isLoadingAccountDetails: true,
  isLoadingYTD: true,
  noExternalAccounts: false
};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    setIsAuthorized: (state: InitialState, action: PayloadAction<boolean>) => {
      state.isAuthorized = action.payload;
    },
    setInterestRate: (state: InitialState, action: PayloadAction<number>) => {
        state.interestRate = action.payload;
    },
    setUser: (state: InitialState, action: PayloadAction<string>) => {
        state.user = action.payload;
    },
    setToken: (state: InitialState, action: PayloadAction<string>) => {
      state.token = action.payload;
  },
    setIsLoadingAccountDetails: (state: InitialState, action: PayloadAction<boolean>) => {
      state.isLoadingAccountDetails = action.payload;
    },
    setIsLoadingYTD: (state: InitialState, action: PayloadAction<boolean>) => {
      state.isLoadingYTD = action.payload;
    },
    setNoExternalAccounts: (state: InitialState, action: PayloadAction<boolean>) => {
      state.noExternalAccounts = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
})

export const {setInterestRate, setIsAuthorized, setUser, setToken, setIsLoadingAccountDetails, setIsLoadingYTD,setNoExternalAccounts, reset} = authSlice.actions;

export default authSlice;
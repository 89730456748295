import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../store";
import {
  InitialState,
  setIsAuthorized,
  setToken,
  setUser,
} from "../store/slices/authSlice";
import CircularProgress from "@mui/material/CircularProgress";
import authAPI from "../store/modules/authAPI";

const AuthWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  
  const [getAuthMutation] = authAPI.useGetAuthMutation();
  const isAuthorized = useSelector(
    (state: TRootState) => (state.auth as InitialState).isAuthorized
  );

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const hasCheckedAuth = useRef(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (isAuthorized || hasCheckedAuth.current) {
          setLoading(false);
          return;
        } else {

          const queryParams = new URLSearchParams(window.location.search);
          let authCode = queryParams.get("code");

          const auth = await getAuthMutation({id: authCode ?? ''}).unwrap();

          dispatch(setIsAuthorized(auth.isLogged));
          dispatch(setUser(auth.userName));
          dispatch(setToken(authCode ?? '')); 
          hasCheckedAuth.current = true;
        }
      } catch (error) {
        console.error("Something went wrong");
        dispatch(setIsAuthorized(false));
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [dispatch, getAuthMutation, isAuthorized]);

  if (loading) {
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}><CircularProgress /></div>;
  }

  return <>{children}</>;
};

export default AuthWrapper;

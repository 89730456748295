import { BaseQueryFn, FetchArgs, fetchBaseQuery,FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery();

const baseQueryTokenCheck: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const results = await baseQuery(args, api, extraOptions)
  return results
}

const api = createApi({
  baseQuery: baseQueryTokenCheck,
  endpoints: () => ({}), // endpoints will be injected from api.modules
  tagTypes: [
    'NARMI', 
    'MX',
  ],
});

export default api;

import { useEffect, useState } from "react";

import { LandingPage } from "./components/LandingPage";
import { Dashboard } from "./components/Dashboard";
import { CashFlowForecast } from "./components/CashFlowForecast";
import { SingleRecommendation } from "./components/SingleRecommendation";
import AuthWrapper from "./utils/authWrapper";

export const App = () => {
  const [selectedPage, setSelectedPage] = useState<
    "LandingPage" | "Dashboard" | "CashFlowForecast" | "SingleRecommendation"
  >("LandingPage");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [selectedPage]);

  return (
      <AuthWrapper>
        {selectedPage === "LandingPage" && (
          <LandingPage setSelectedPage={setSelectedPage} />
        )}
        {selectedPage === "Dashboard" && (
          <Dashboard />
        )}
        {selectedPage === "CashFlowForecast" && (
          <CashFlowForecast setSelectedPage={setSelectedPage} />
        )}
        {selectedPage === "SingleRecommendation" && <SingleRecommendation />}
      </AuthWrapper>
  );
};
